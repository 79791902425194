import UAParser from 'ua-parser-js';
import * as semver from 'semver';

// Check if given user agent string is acceptable.
function evaluateUA(agent) {
  var parser = new UAParser(agent);
  var specs  = parser.getResult();

  // Allow unknown clients to be on safe side;
  if (!specs.browser)
    return 200;

  var browserName = specs.browser.name;
  if (!browserName)
    return 201;

  // Per-browser restrictions.
  if (browserName == 'IE')
    return -300;

  var browserVersion = specs.browser.version;
  if (browserVersion) {
    let bv = new semver.SemVer(semver.coerce(browserVersion));

    if (/Chrome/.test(browserName) && semver.lt(bv, '85.0.0'))
      return -400;

    if (/Firefox/.test(browserName) && semver.lt(bv, '79.0.0'))
      return -500;

    if (/Safari/.test(browserName) && semver.lt(bv, '14.0.0'))
      return -600;
  }

  // Allow unknown OSs.
  if (!specs.os)
    return 1200;

  var osName = specs.os.name;
  if (!osName || !specs.os.version)
    return 1201;

  var osVersion = new semver.SemVer(semver.coerce(specs.os.version));

  // Per-OS restrictions
  if (/Android/.test(osName) && semver.lt(osVersion, '9.0.0'))
    return -4000;

  return 200;
}

// Special case certain crawlers.
function isValidCrawler(agent) {
  return /Google|Y!|Yahoo|Bing|AhrefsBot|Linespider/i.test(agent);
}

// Check if browser user agent is valid.
function isValidUA() {
  if (!window.navigator)
    return -100;

  var agent = window.navigator.userAgent;
  if (!agent)
    return -101;

  if (isValidCrawler(agent))
    return 100;

  return evaluateUA(agent);
}

function checkValidUA() {
  var reason = isValidUA();

  window.asjCompatibleBrowser = reason > 0;
  if (window.asjCompatibleBrowser)
    return;

  window.location = 'https://dev.asj-net.com/html/invalid-user-agent.html?r=' + String(reason);
}

checkValidUA();
